import { afterNextRender, ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { NavigationEnd, NavigationError, Router, RouterOutlet } from '@angular/router';
import { AppConfig } from './@common/configuration/app-config.service';
import { ImageDirective } from './@common/DOM/image.directive';
import { ModalService } from './@domain/modal/modal.service';
import { FooterComponent } from './@ui/footer/footer.component';
import { HeaderComponent } from './@ui/header/header.component';

@Component({
    selector: 'app-root',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        HeaderComponent,
        RouterOutlet,
        FooterComponent,
        ImageDirective
    ],
    styles: `
        :host {
            display: flex;
            flex-direction: column;
            justify-content: center;

            main {
                padding-top: 4.5rem;
                min-height: 100dvh;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .content {
                flex: 1;
            }
        }
    `,
    template: `
        <app-header [searchQuery]="query()"/>
        <main class="content">
            <router-outlet/>
        </main>
        <app-footer/>
    `
})
export class AppComponent {
    private modalService = inject(ModalService);
    private appConfig = inject(AppConfig);
    private router = inject(Router);

    public query = input<string>();

    constructor() {
        console.debug('Version:', this.appConfig.version);

        afterNextRender(() => {
            document.body.addEventListener('click', (e: Event) => {
                this.modalService.checkModalWindow(e);
            });

            this.router.events
                .subscribe(evt => {
                    if (evt instanceof NavigationEnd) {
                        this.modalService.close()
                    }

                    if (evt instanceof NavigationError && evt.error.message.includes('dynamically imported')) {
                        window.location.assign(evt.url);
                    }
                });
        })
    }
}
