import { Routes } from '@angular/router';
import { LandingPage } from '../@page/app/landing/landing.page';
import ProfilePage from '../@page/app/profile/profile.page';
import { UserEditComponent } from '../@ui/content/forms/search/edit/user-edit.component';
import { UserBookmarksComponent } from '../@ui/content/sections/user-profile-table/user-bookmarks/user-bookmarks.component';
import { UserFollowingComponent } from '../@ui/content/sections/user-profile-table/user-following/user-following.component';
import { UserPlaylistComponent } from '../@ui/content/sections/user-profile-table/user-playlist/user-playlist.component';
import { UserUploadedVideosComponent } from '../@ui/content/sections/user-profile-table/user-uploaded-videos/user-uploaded-videos.component';
import { UploadForm1Component } from '../@ui/content/upload-content/upload-form1/upload-form1.component';
import { UploadForm2Component } from '../@ui/content/upload-content/upload-form2/upload-form2.component';
import { UploadForm3Component } from '../@ui/content/upload-content/upload-form3/upload-form3.component';
import { UploadForm4Component } from '../@ui/content/upload-content/upload-form4/upload-form4.component';
import { authGuardFn } from './guards/auth.guard';
import { slugGuardFn } from './guards/slug.guard';
import { AppRoutes } from './types/app-route';

export const APP_ROUTES: Routes = [
    {
        path: AppRoutes.Home,
        pathMatch: 'full',
        component: LandingPage
    },
    {
        path: AppRoutes.Register,
        loadComponent: () => import('../@page/app/register/register.page')
    },
    {
        path: AppRoutes.Login,
        loadComponent: () => import('../@page/app/login/login.page')
    },
    {
        path: AppRoutes.ForgotPassword,
        loadComponent: () => import('../@page/app/forgot-password/forgot-password.page')
    },
    {
        path: AppRoutes.Profile,
        canActivate: [ authGuardFn ],
        loadChildren: () => [
            {
                path: 'edit',
                component: UserEditComponent
            },
            {
                path: '',
                component: ProfilePage,
                children: [
                    { path: 'videos', component: UserUploadedVideosComponent },
                    { path: 'playlists', component: UserPlaylistComponent },
                    { path: 'following', component: UserFollowingComponent },
                    { path: 'bookmarks', component: UserBookmarksComponent },
                    { path: '**', redirectTo: 'playlists', pathMatch: 'full' }
                ]
            },
            {
                path: '**',
                redirectTo: '',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: AppRoutes.Events,
        loadComponent: () => import('../@page/app/events/events.page'),
    },
    {
        path: `${ AppRoutes.Events }/:slug`,
        loadComponent: () => import('../@page/app/event/event.page'),
    },
    {
        path: AppRoutes.Videos,
        loadComponent: () => import('../@page/app/videos/videos.page'),
    },
    {
        path: AppRoutes.DeprecatedLectures,
        loadComponent: () => import('../@page/app/videos/videos.page'),
    },
    {
        path: `${ AppRoutes.Videos }/${ AppRoutes.Slug }`,
        loadComponent: () => import('../@page/app/video/video.page'),
    },
    {
        path: `${ AppRoutes.DeprecatedLectures }/${ AppRoutes.Slug }`,
        loadComponent: () => import('../@page/app/video/video.page'),
    },
    {
        path: `${ AppRoutes.Videos }/${ AppRoutes.Slug }/:language`,
        loadComponent: () => import('../@page/app/synchronization/synchronization.page'),
    },
    {
        path: AppRoutes.News,
        loadComponent: () => import('../@page/app/news/news.page'),
    },
    {
        path: AppRoutes.Categories,
        loadComponent: () => import('../@page/app/categories/categories.page'),
    },
    {
        path: `${ AppRoutes.Categories }/:id`,
        loadComponent: () => import('../@page/app/category/category.page'),
    },
    {
        path: AppRoutes.History,
        loadComponent: () => import('../@page/app/history/history.page'),
        canActivate: [ authGuardFn ],
    },
    {
        path: `${ AppRoutes.Playlists }/:id`,
        loadComponent: () => import('../@page/app/playlist/playlist.page'),
        canActivate: [ authGuardFn ],
    },
    {
        path: `${ AppRoutes.Authors }/:slug`,
        loadComponent: () => import('../@page/app/author/author.page'),
    },
    {
        path: `${ AppRoutes.LecturerDeprecated }/:id`,
        loadComponent: () => import('../@page/app/author/author.page'),
    },
    {
        path: AppRoutes.LectureAgreement,
        loadComponent: () => import('../@page/app/author-agreement/author-agreement.page'),
    },
    {
        path: 'activate/:id/:token',
        loadComponent: () => import('../@page/app/activate-account/activate-account.page'),
    },
    {
        path: 'reset-password/:id/:token',
        loadComponent: () => import('../@page/app/password-change/password-change.page'),
    },
    {
        path: 'upload-video',
        loadComponent: () => import('../@page/app/upload/upload.page'),
        loadChildren: () => [
            { path: 'step/1', component: UploadForm1Component },
            { path: 'step/2', component: UploadForm2Component },
            { path: 'step/3', component: UploadForm3Component },
            { path: 'step/4', component: UploadForm4Component },
            { path: '**', redirectTo: 'step/1', pathMatch: 'full' }
        ]
    },
    {
        path: AppRoutes.Search,
        loadComponent: () => import('../@page/app/search/search.page')
    },
    {
        path: AppRoutes.Company,
        loadComponent: () => import('../@page/app/company/company.page')
    },
    {
        path: AppRoutes.PrivacyTermsOfUse,
        loadComponent: () => import('../@page/app/privacy-terms-of-use/privacy-terms.page')
    },
    {
        path: AppRoutes.Slug,
        pathMatch: 'full',
        canActivate: [ slugGuardFn ],
        loadComponent: () => import('../@page/app/not-found/not-found.page')
    },
];
