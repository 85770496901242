import { isPlatformServer } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { RESPONSE } from 'server/response.token';
import { SlugService } from '../../@domain/slug/application/slug.service';
import { SlugType } from '../../@domain/slug/entities/slug-type.enum';
import { GenericRoute } from '../routes';
import { AppRoutes } from '../types/app-route';

export const slugGuardFn: CanActivateFn = async (route) => {
    const slugService = inject(SlugService);
    const router = inject(Router);
    const platformId = inject(PLATFORM_ID);
    const response = inject(RESPONSE);

    const slug: string = route.params.slug;
    const fileExtensions = [ '.css', '.js' ];

    if (!!slug && fileExtensions.some(ext => slug.includes(ext))) {
        console.error('received slug', route.params.slug, typeof route.params.slug);
        return false;
    }

    const slugType = await firstValueFrom(slugService.getSlugType(slug));
    const redirectPath = getRedirectPath(slugType, slug);

    console.log(`redirecting slug: ${ slug } to`, redirectPath);
    if (isPlatformServer(platformId)) {
        response.redirect(301, redirectPath);
        response.send();
        return true;
    } else {
        return router.createUrlTree([ redirectPath ]);
    }
};

function getRedirectPath(slugType: string | null, slug: string) {
    if (slugType === SlugType.Event) {
        return '/' + AppRoutes.Events + '/' + slug
    }

    if (slugType === SlugType.Lecture) {
        return '/' + AppRoutes.Videos + '/' + slug
    }

    if (slugType === SlugType.Author) {
        return '/' + AppRoutes.Authors + '/' + slug
    }

    return '/' + GenericRoute.NotFound
}
